import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Class.css';


function Class () {

    

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 4,
        initialSlide: 0,
      
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite:false,
              dots: false
            }
          },
          {
            breakpoint: 840,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
              dots: false
            }
          },
          {
            breakpoint: 620,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false
            }
          }
        ]
      };
      

  return (
    <div className='c-section'>
        <div className='class-bg-img'>
            <div className='c-bg-section'>
                <h1 className='class-title'>“Class Schedule</h1>
                    <div className="cards-sec-all">
                        
                        <Slider {...settings}>
                            <div>
                                <div className='info-card'>
                                    <img src='assets/c_room_bg.png' alt='' className='c-room-bg'/>
                                    <h2 className='grade'>Grade - 6</h2>
                                    <img src='assets/underline-curve.png' alt='' className='curve'/>
                                    <div className='c-info'>
                                        <p>Date<span className='c-info-text'> - Sunday</span></p>
                                        <p>Time<span className='c-info-text'>- At 2.00pm</span></p>
                                        <p>Place<span className='c-info-text'> - at Warren</span></p>
                                        <p>Type<span className='c-info-text'>- Physical</span></p>
                                    </div>
                                    <div className='stu-num'>
                                        <p className='stu-icon'><i className="fa-solid fa-user-graduate"></i>41 Students</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='info-card'>
                                    <img src='assets/c_room_bg.png' alt='' className='c-room-bg'/>
                                    <h2 className='grade'>Grade - 7</h2>
                                    <img src='assets/underline-curve.png' alt='' className='curve'/>
                                    <div className='c-info'>
                                        <p>Date<span className='c-info-text'> - Sunday</span></p>
                                        <p>Time<span className='c-info-text'>- At 2.00pm</span></p>
                                        <p>Place<span className='c-info-text'> - at Warren</span></p>
                                        <p>Type<span className='c-info-text'>- Physical</span></p>
                                    </div>
                                    <div className='stu-num'>
                                        <p className='stu-icon'><i className="fa-solid fa-user-graduate"></i>41 Students</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div>
                                <div className='info-card'>
                                    <img src='assets/c_room_bg.png' alt='' className='c-room-bg'/>
                                    <h2 className='grade'>Grade - 8</h2>
                                    <img src='assets/underline-curve.png' alt='' className='curve'/>
                                    <div className='c-info'>
                                        <p>Date<span className='c-info-text'> - Sunday</span></p>
                                        <p>Time<span className='c-info-text'>- At 2.00pm</span></p>
                                        <p>Place<span className='c-info-text'> - at Warren</span></p>
                                        <p>Type<span className='c-info-text'>- Physical</span></p>
                                    </div>
                                    <div className='stu-num'>
                                        <p className='stu-icon'><i className="fa-solid fa-user-graduate"></i>41 Students</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='info-card'>
                                    <img src='assets/c_room_bg.png' alt='' className='c-room-bg'/>
                                    <h2 className='grade'>Grade - 9</h2>
                                    <img src='assets/underline-curve.png' alt='' className='curve'/>
                                    <div className='c-info'>
                                        <p>Date<span className='c-info-text'> - Sunday</span></p>
                                        <p>Time<span className='c-info-text'>- At 2.00pm</span></p>
                                        <p>Place<span className='c-info-text'> - at Warren</span></p>
                                        <p>Type<span className='c-info-text'>- Physical</span></p>
                                    </div>
                                    <div className='stu-num'>
                                        <p className='stu-icon'><i className="fa-solid fa-user-graduate"></i>41 Students</p>
                                    </div>
                                </div>
                            </div>              
                        
                        </Slider>
                        </div>      
                 <div className="vm-btn">
                    <button className='view-more-button'> View More</button>
                </div>   
                
            </div>
        </div>
    </div>
  )
}

export default Class
import React from 'react'
import './Footer.css'
import { NavLink } from 'react-router-dom';

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { Autoplay, EffectCoverflow, Pagination } from "swiper";

function Footer() {
  return (
    <div className="footer">
        <div className="footer-container">
            <div className="footer-row">
                <div className="footer-col footer-col-img">
                      <img src="assets/Logo1.png" alt="" />
                </div>
                <div className="footer-col footer-col-address">
                    <h4>Address</h4>
                    <ul className='footer-ul'>
                        <li>
                            <a href="/"><i class="fa-sharp fa-solid fa-location-dot"></i>
                                
                                <Swiper 
                                    pagination={true}
                                    modules={[Autoplay,Pagination]} 
                                    className="address-Swiper"
                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: true,
                                    }}>

                                    <SwiperSlide>
                                        <i className='footer-li location'>
                                            2A,58,<br /> 
                                            Crescent,<br /> 
                                            Narre Warren, <br />
                                            VIC 3809
                                        </i>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <i className='footer-li location'>
                                            2nd Floor,10,<br /> 
                                            Hamilton Place,<br /> 
                                            Mount Waverly, <br />
                                            VIC 3149
                                        </i>
                                    </SwiperSlide>
                                </Swiper>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="footer-col">
                    <h4>Reach Out To Us</h4>
                    <ul className='footer-ul'>                      
                        <li className='footer-li callmail'><i class="fa-solid fa-phone"/> <a href="https://wa.me/+61425660556">+61425660556</a></li>
                        <li className='footer-li callmail'><i class="fa-solid fa-envelope"/> <a href="mailto:info@mathshubmelbourne.com.au">info@mathshubmelbourne.com.au</a></li>
                    </ul>
                </div>
                <div className="footer-col ">
                    <h4>General</h4>
                    <ul className='footer-ul'>
                        <li><NavLink to="/" exact >Home</NavLink></li>
                        <li><NavLink to="/about">About</NavLink></li>
                        <li><NavLink to="/contact"  >Contact Us</NavLink></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class='social-media'>
            <a href="https://www.instagram.com/mathshubmelbourne"><i class="fa-brands fa-instagram"></i></a>
            <a href="https://www.linkedin.com/in/maths-hub-melbourne-62a219235"><i class="fa-brands fa-linkedin"></i></a>
            <a href="/"><i class="fa-brands fa-twitter"></i></a>
            <a href="https://web.facebook.com/melbournemathshub"><i class="fa-brands fa-facebook"></i></a>
        </div>
        <div>
            <h5 className='developer'>Engineered and Empowered by Mr.Tech and SoftByte</h5>
        </div>
    </div> 
  )
}

export default Footer;
